<template>
  <div id="components-form-demo-vuex">
    <a-row>
      <a-col :span="12">
        <div class="form_field">
          <label>{{ $t("FormFirstName") }}</label>
          <a-input
            v-model="form.first_name"
            :placeholder="$t('FormFirstNamePlace')"
          />
        </div>
        <div class="form_field">
          <label>{{ $t("FormLastName") }}</label>
          <a-input
            v-model="form.last_name"
            :placeholder="$t('FormLastNamePlace')"
          />
        </div>
        <div class="form_field">
          <label>{{ $t("FormMiddleName") }}</label>
          <a-input
            v-model="form.middle_name"
            :placeholder="$t('FormMiddleNamePlace')"
          />
        </div>
        <div class="form_field">
          <label>{{ $t("FormBirthDate") }}</label>
          <a-date-picker @change="onChange" />
        </div>
        <div class="form_field">
          <label>{{ $t("FormGender") }}</label>
          <a-radio-group v-model="form.gender">
            <a-radio
              v-for="(gender, index) in genderList"
              :key="index"
              :value="gender.value"
            >
              {{ gender.title }}
            </a-radio>
          </a-radio-group>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="form_field">
          <label>{{ $t("FormUsername") }}</label>
          <a-input
            v-model="form.username"
            :placeholder="$t('FormUsernamePlace')"
          />
        </div>
        <div class="form_field">
          <label>{{ $t("FormPassword") }}</label>
          <a-input
            v-model="form.password"
            :placeholder="$t('FormPasswordPlace')"
          />
        </div>
        <div class="form_field">
          <label>{{ $t("FormPhone") }}</label>
          <a-input
            v-model="form.phone"
            :placeholder="$t('FormPhonePlace')"
            name="phone"
            type="tel"
          />
        </div>
        <div class="form_field">
          <label>{{ $t("FormProfession") }}</label>
          <a-input
            v-model="form.profession"
            :placeholder="$t('FormProfessionPlace')"
          />
        </div>
        <div class="form_field">
          <label>{{ $t("FormRole") }}</label>
          <a-select
            v-model="form.role"
            default-value="Admin"
            style="width: 120px"
          >
            <a-select-option
              v-for="role in roleList"
              :key="role.title"
              :value="role.value"
            >
              {{ role.title }}
            </a-select-option>
          </a-select>
        </div>
        <div class="form_field">
          <a-button type="primary" @click="handleSubmit">
            <a-icon type="plus" />{{ $t("Add") }}
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators"
import cloneDeep from "lodash/cloneDeep"
export default {
  data() {
    return {
      form: {
        id: null,
        username: "",
        password: "",
        last_name: "",
        first_name: "",
        middle_name: "",
        phone: "",
        gender: "",
        birth_date: null,
        profession: "",
        role: ""
      },
      roleList: [
        {
          value: "admin",
          title: "Admin"
        }
        // {
        //   value: "Moderator",
        //   title: "Moderator"
        // },
        // {
        //   value: "Moderator2",
        //   title: "Moderator-2"
        // },
        // {
        //   value: "Moderator3",
        //   title: "Moderator-3"
        // }
      ],
      genderList: [
        {
          value: 1,
          title: this.$t("FormMale")
        },
        {
          value: 2,
          title: this.$t("FormFemale")
        }
      ],
      name: "",
      password: "",
      username: ""
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    username: {
      required,
      minLength: minLength(5)
    },
    password: {
      required,
      minLength: minLength(8)
    }
  },
  created() {
    this.form.id = (this.$route.params && this.$route.params.id) || null
    this.fetchData()
  },
  methods: {
    async handleSubmit() {
      let newData = cloneDeep(this.form)
      delete newData.photo
      delete newData.thumb
      delete newData.address
      newData = {
        ...newData
      }
      let f = new FormData()
      Object.keys(newData).forEach((key) => {
        key != "birth_date" && key != "password" && f.append(key, newData[key])
      })
      let res = this.form.id
        ? await this.$api.patch("/admin/user/" + this.form.id + "/update", f)
        : await this.$api.post("/admin/user/create", f)
      res &&
        this.$router.push({
          name: "users"
        })
    },
    fetchData() {
      if (this.$route.params && this.$route.params.id) {
        this.$store
          .dispatch("user/detail", this.$route.params.id)
          .then((res) => {
            this.form = res && res.data
          })
      }
    },
    onChange(e) {
      e = this.moment(e).format("DD-MM-YYYY")
      this.form.date = e
    }
  }
}
</script>
<style>
#components-form-demo-vuex .language-bash {
  max-width: 400px;
  border-radius: 6px;
  margin-top: 24px;
}
form {
  padding: 64px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
form > div {
  width: 30%;
  margin: 15px 0;
}
form > div > label {
  display: block;
}

.form_field {
  margin: 10px 0;
  height: 70px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.form_field > .deputy_image {
  width: 70px;
  height: 70px;
  border: 1px solid grey;
  border-radius: 40px;
  overflow: hidden;
  display: inline;
  margin-right: 30px;
}
.form_field > .deputy_image > img {
  width: 100%;
  height: 100%;
}
.form_field > label {
  margin-bottom: 7px;
  display: block;
  width: 100%;
}
.form_field > div {
  width: 100%;
}
.form_field > button {
  margin-left: auto;
}
.form_field_btn {
  margin: 10px 0;
  height: 70px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.form_field > .ant-select {
  width: 100% !important;
}
.form_field > .ant-calendar-picker {
  width: 100% !important;
}
</style>
